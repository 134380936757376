import React,{ useRef, useState } from "react"; 
import Button from "./atoms/Button"; 
import { ArrowRight } from "lucide-react"; 
const faqList = [
   {
    question: "Is Roach Home Improvement licensed and insured?",
    answer: <div className="space-y-2 leading-relaxed">Yes. State of Michigan license # 2102203135, Workers Compensation and Liability through Frankenmuth Insurance.</div>,
   },
    {
      question: "How long has Roach Home Improvement been in business?",
      answer: <div className="space-y-2 leading-relaxed">We have been in business for 20 years doing roof installations and many other home improvement projects.</div>,
    },
    {
      question: "Does Roach Home Improvement remove old roofing material or shingle over the existing roof?",
      answer: <div className="space-y-2 leading-relaxed">We do not recommend applying a new layer of shingles over the existing roof. At Roach we want to remove all existing roofing material to insure that the roof decking is adequate for the new roof application (No rotten wood or large gaps). Michigan code states no more than 2 layers of roofing materials plus total removal for the manufacturers warranty.</div>,
    },
    {
      question: "Why is your price higher than some of your competitors?",
      answer: <div className="space-y-2 leading-relaxed">At Roach Home Improvement, we are not always the lowest estimate because we are licensed & insured (this is your legal protection), along with standing behind our installations. If you have questions, we will address your concerns during and after the installation (No hit & run with us) Our experienced project managers and crew leaders will be with you from start to finish.</div>,
    },
    {
      question: "How long will the roofing application take on my home?",
      answer: <div className="space-y-2 leading-relaxed">No one answer to this because each home is different. An easy 1 story gable or hip roof house will be complete in 1 day. A 2 story multi slope roof with multiple layers of removal will be complete in 2 days. </div>,
    }
];


const Item = ({ item }) => {
  const accordion = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <li>
      <button
        className="relative flex gap-2 items-center w-full py-5 text-base font-semibold text-left border-t md:text-lg border-base-content/10"
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(!isOpen);
        }}
        aria-expanded={isOpen}
      >
        <span
          className={`font-display flex-1 text-base-content ${isOpen ? "text-primary" : ""}`}
        >
          {item?.question}
        </span>
        <svg
          className={`flex-shrink-0 w-4 h-4 ml-auto fill-current`}
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            y="7"
            width="16"
            height="2"
            rx="1"
            className={`transform origin-center transition duration-200 ease-out ${
              isOpen && "rotate-180"
            }`}
          />
          <rect
            y="7"
            width="16"
            height="2"
            rx="1"
            className={`transform origin-center rotate-90 transition duration-200 ease-out ${
              isOpen && "rotate-180 hidden"
            }`}
          />
        </svg>
      </button>

      <div
        ref={accordion}
        className={`transition-all duration-300 ease-in-out opacity-80 overflow-hidden`}
        style={
          isOpen
            ? { maxHeight: accordion?.current?.scrollHeight, opacity: 1 }
            : { maxHeight: 0, opacity: 0 }
        }
      >
        <div className="pb-5 leading-relaxed">{item?.answer}</div>
      </div>
    </li>
  );
};


const FAQ = ({ title, classnames }) => {

  return (
    <div className={`relative py-16 bg-neutral-100  overflow-hidden ${classnames}`}>
      
       <div className="max-w-7xl mx-auto  flex  justify-center">
       
        <div className="hidden absolute inset-0 overflow-hidden rounded-3xl lg:block" aria-hidden="true">
              <svg
                className="absolute bottom-full left-full transform translate-y-1/3 -translate-x-2/3 xl:bottom-auto xl:top-0 xl:translate-y-0"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-neutral-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
              </svg>
              <svg
                className="absolute top-full transform -translate-y-1/3 -translate-x-1/3 xl:-translate-y-1/2"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-neutral-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
              </svg>
            </div>
        
          

             
            <div className="relative w-full max-w-3xl  md:py-12 px-4 space-y-4  sm:py-12 sm:px-6 ">
              <h2 className="text-center text-3xl md:text-4xl font-display text-nuetral-900 font-extrabold">
              Frequently Asked Questions
              </h2>
              <p className="text-neutral-600 text-lg text-center">
              Home owners have questions, Roach Home Improvement provides answers.
              </p>
             
              <ul className="basis-1/2 mt-4">
          {faqList.map((item, i) => (
            <Item key={i} item={item} />
          ))}
        </ul>
              <Button
              size="big"
              color="red"
                  to="/contact/"
                title={"Get Started with " + title}
                >
                Ask a Question<ArrowRight size={16} className="ml-2"/>
              </Button>
            </div>
           
      </div>
    </div>
  );
};
 

export default FAQ;
