import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { StaticQuery, graphql } from "gatsby";
import Button from  "../atoms/Button"
import { ArrowRight } from "lucide-react";

const RoofingCalculator = ({size}) => (
  <StaticQuery
    query={graphql`
      query {
        fileName: file(relativePath: { eq: "roofing-bg.png" }) {
          childImageSharp {
            gatsbyImageData(width: 800)
          }
        }
      }
    `}
    render={(data) => {
      return (
        
        <>
        <GatsbyImage
            className="absolute top-0 left-0 w-full max-w-none h-full absolute-important h-full"
          image={getImage(data.fileName.childImageSharp)}
          alt="Roach Home Improvement Happy Customers"
        />  
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 z-0"></div>

        <div className="z-10 relative max-w-4xl text-center mx-auto">  
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className="w-9 h-9 mx-auto text-white">   
        <path  fill="currentColor" d="M352 160V448c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V160H352zm0-32H32V64c0-17.7 14.3-32 32-32H320c17.7 0 32 14.3 32 32v64zm32 0V64c0-35.3-28.7-64-64-64H64C28.7 0 0 28.7 0 64v64 16 16V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160 144 128zM72 224a24 24 0 1 0 48 0 24 24 0 1 0 -48 0zm24 72a24 24 0 1 0 0 48 24 24 0 1 0 0-48zm72-72a24 24 0 1 0 48 0 24 24 0 1 0 -48 0zm24 72a24 24 0 1 0 0 48 24 24 0 1 0 0-48zm72-72a24 24 0 1 0 48 0 24 24 0 1 0 -48 0zm24 72a24 24 0 1 0 0 48 24 24 0 1 0 0-48zM264 416a24 24 0 1 0 48 0 24 24 0 1 0 -48 0zM80 400c-8.8 0-16 7.2-16 16s7.2 16 16 16H208c8.8 0 16-7.2 16-16s-7.2-16-16-16H80z"/></svg>
          <h2 className={`${size == 'sm' ? 'hidden text-base ': 'text-lg '} font-bold text-white`}>Roofing Calculator</h2>
          <h2 className={`${size == 'sm' ? 'text-xl': 'text-2xl md:text-4xl'}  max-w-2xl mx-auto font-bold text-center text-white`}>Calculate Your Roof Cost <br/>in <span className="text-brand-600">3 Easy Steps</span></h2>
          <div className="flex justify-center mt-4">
          <Button size="xl" to={'/calculator/'} color="brandGradient">
            Get Pricing <ArrowRight size={20} className="ml-2" />
              </Button>
            </div>
           </div> </>
      );
    }}
  />
);
export default RoofingCalculator;
